import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../user.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { LoggingHelper } from '../../core/logging/helper';

@Injectable({
  providedIn: 'root'
})
export class AuthUserGuard extends LoggingHelper implements CanActivate {

  constructor(private _router: Router, private _user: UserService) { super(); }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._user.getCurrentUser().pipe(
      catchError((err) => {
        this.logError(err);
        return of(null);
      }),
      map((user) => {
        if (user != null) {
          // logged in
          return true;
        }
        else {
          // not logged in so redirect to login page with the return url
          void this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
          return false;
        }
      }));
  }

}
