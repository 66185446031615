<footer [class.hidden]="!showFooter" class="mat-typography">
  <mat-divider></mat-divider>
  <h4>
    &copy; Copyright 2018-2024 Department of Justice Roleplay
    <br />
    <span *ngIf="showAdvancedOptions">
      <a href="javascript:;" (click)="exportLogs()">Export Client Logs</a>
      &nbsp;
      <a href="javascript:;"
        (click)="toggleTabletMode()">{{ isTablet ? 'Disable Tablet Mode' : 'Enable Tablet Mode' }}</a>
    </span>
  </h4>
</footer>
