import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AppDataService } from '../core/app-data.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

    private apiUrl: string = environment.DOJAPIUrl;
    private apiKey: string = environment.DOJAPIKey;

    constructor(private _http: HttpClient, private _appData: AppDataService) {
    }

    buildHeaders(showSpinner = false): HttpHeaders {
        const token = this._appData.Token;

        const headers = {
            'Content-Type': 'application/json',
            'X-DOJ-APIKey': this.apiKey
        };

        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        if (!showSpinner) {
            headers['X-DOJ-No-Spinner'] = 'Yes';
        }

        return new HttpHeaders(headers);
    }

    sendGetAsync<T = any>(path: string, showSpinner = true): Observable<HttpResponse<T>> {
        const headers = this.buildHeaders(showSpinner);

        return this._http.get<T>(`${this.apiUrl}/${path}`, { headers: headers, observe: 'response' })
            .pipe(catchError((err) => this.handleError(err as HttpErrorResponse)));
    }

    sendPostAsync<T = any>(path: string, body: any, showSpinner = true): Observable<HttpResponse<T>> {
        const headers = this.buildHeaders(showSpinner);

        return this._http.post<T>(`${this.apiUrl}/${path}`, body, { headers: headers, observe: 'response' })
            .pipe(catchError((err) => this.handleError(err as HttpErrorResponse)));
    }

    sendPutAsync<T = any>(path: string, body: any, showSpinner = true): Observable<HttpResponse<T>> {
        const headers = this.buildHeaders(showSpinner);

        return this._http.put<T>(`${this.apiUrl}/${path}`, body, { headers: headers, observe: 'response' })
            .pipe(catchError((err) => this.handleError(err as HttpErrorResponse)));
    }

    sendDeleteAsync<T = any>(path: string, showSpinner = true): Observable<HttpResponse<T>> {
        const headers = this.buildHeaders(showSpinner);

        return this._http.delete<T>(`${this.apiUrl}/${path}`, { headers: headers, observe: 'response' })
            .pipe(catchError((err) => this.handleError(err as HttpErrorResponse)));
    }

    private handleError(error: HttpErrorResponse): Observable<never> {
        let errorMsg: string = `An error occurred; please try again later.`;

        if (error.error instanceof ErrorEvent) {
            errorMsg = `An error occurred: ${error.error.message}`;
        }
        else {
            if (error.status === 401) {
                if (error.url.includes(`/api/auth/`)) {
                    errorMsg = `The e-mail address and/or password you provided did not match our records.`;
                }

                this._appData.logOut();
            }
            else if (error.status === 403) {
                errorMsg = `You do not have access to perform that action`;
            }
            else if (error.status === 404) {
                errorMsg = '404 Not Found';
            }
            else {
                errorMsg = `${error.error}`;
            }
        }

        return throwError(errorMsg);
    }
}
