import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, CanActivateChild } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { UserService } from '../user.service';
import { AppDataService } from '../../core/app-data.service';
import { EDepartment } from '../../models/enums/department.enum';
import { CadPatrolService } from '../cad-patrol.service';

@Injectable({
  providedIn: 'root'
})
export class AuthLivemapGuard implements CanActivateChild {
  constructor(private _router: Router, private _user: UserService, private _data: AppDataService, private _patrol: CadPatrolService) {
  }

  canActivateChild(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this._user.getCurrentUser().pipe(mergeMap((user) => {
      // If staff, allow them in
      if (this._data.isUserStaff()) {
        return of(true);
      }

      const deptIds = user.departments.map((dept) => dept.departmentId);

      // If they are in the dispatch department check if they are 10-41 as a LEO
      if (deptIds.includes(EDepartment.COMM)) {
        return this._patrol.getPatrolUnitAsync(user.userId).pipe(map((patrolUnit) => {
          // If the user is not logged in
          if (!patrolUnit || !patrolUnit.isConnected) {
            // Kick them out if they are not active as comms
            alert('You are not 10-41 as a Dispatcher. You are not authorized to use the Livemap.');
            return this._router.parseUrl('/user');
          }

          // If they are logged in as something other than communications, redirect them home
          if (patrolUnit.departmentId !== EDepartment.COMM) {
            alert('You are currently 10-41 as a LEO/Fire/Civ Unit. Please do not use Livemap in patrol');
            return this._router.parseUrl('/user');
          } else {
            return true;
          }
        }));
      } else {
        alert('You are not a member of Communications Department. You are not authorized to use Livemap');
        return of(this._router.parseUrl('/user'));
      }

    }));
  }

}
