import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {

  constructor(private _domSanitizer: DomSanitizer) { }

  transform(value: string, ..._args: any[]): any {
    return this._domSanitizer.bypassSecurityTrustHtml(this._linkify(value));
  }

  /**
   * Replace all http, https, or ftp links in the given `text` with <a> elements to linkify them
   * It also sanitizes all text by interpreting them as a text node
   * @param text The text to detect links in
   */
  private _linkify(text: string): string {
    const safeText = text; // DOM Sanitization not needed anymore because of the custom implementation
    const regex = /(((ftp|https?):\/\/)[\-\w@:%_\+.~#?,&\/\/=]+)/g;

    const linkPositions: [number, number][] = [];

    let match = regex.exec(safeText);

    // Count the start and end positions of all links in the text
    while (match) {
      linkPositions.push([match.index, match.index + match[0].length]);

      match = regex.exec(safeText);
    }

    const container = document.createElement('div');

    // If there are no links just add all of the text as a text node
    if (linkPositions.length === 0) {
      const textNode = document.createTextNode(safeText);
      container.appendChild(textNode);
    } else {
      // Otherwise iterate over all positions, and create nodes for text before the link, and the link itself
      let i = 0;
      for (const linkPos of linkPositions) {
        const preText = safeText.substring(i === 0 ? 0 : linkPositions[i - 1][1], linkPos[0]);

        if (preText.length > 0) {
          const preTextNode = document.createTextNode(preText);
          container.appendChild(preTextNode);
        }

        const linkNode = document.createElement('a');
        const link = safeText.substring(linkPos[0], linkPos[1]);
        linkNode.text = link;
        linkNode.href = link;
        linkNode.target = '_blank';
        container.appendChild(linkNode);

        i++;
      }

      // If the string did not end with a link, add the final text
      const lastLinkPos = linkPositions[linkPositions.length - 1];
      if (lastLinkPos[1] !== safeText.length - 1) {
        const textNode = document.createTextNode(safeText.substring(lastLinkPos[1], safeText.length));
        container.appendChild(textNode);
      }
    }

    const innerHtml = container.innerHTML;

    // Clean up the container before returning
    container.remove();

    return innerHtml;
  }

}
