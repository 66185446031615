import { Component, OnDestroy } from '@angular/core';
import { AppDataService } from '../../../core/app-data.service';
import { EDepartment } from '../../../models/enums/department.enum';
import { IServer } from '../../../models/interfaces/IServer';
import { CadPatrolService } from '../../../services/cad-patrol.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment.live';

@Component({
  selector: 'app-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: [
    './navbar.component.scss'
  ]
})

export class NavBarComponent implements OnDestroy {
  isAdmin = false;
  isStaff = false;
  shouldShowLivemap = false;
  isTablet = false;
  isBWAU = false;
  isCivPrimary = false;

  isDev = !environment.production;

  servers: IServer[];

  private _destroyed$ = new Subject();

  constructor(private _appData: AppDataService, private _patrol: CadPatrolService) {
    this.isAdmin = this._appData.isUserAdmin();
    this.isStaff = this._appData.isUserStaff();
    this.isBWAU = this._appData.isUserBWAU();

    this.isCivPrimary = this._appData.User.departments.find((u) => u.isPrimary)?.departmentId === EDepartment.CIV;

    // Temporarily remove civ access
    this.shouldShowLivemap = this.isStaff;
    // this.shouldShowLivemap = this.isStaff || this._appData.User.departments.map((dept) => dept.departmentId).includes(EDepartment.CIV);

    this.isTablet = this._appData.isTablet;

    this._patrol.getServersAsync().pipe(takeUntil(this._destroyed$)).subscribe((servers) => {
      this.servers = servers;
    });
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }

  /**
   * Logs out the current user
   */
  logout() {
    this._appData.logOut();
  }
}
