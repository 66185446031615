import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { IDBPDatabase, IDBPTransaction, openDB } from 'idb';
import { map } from 'rxjs/operators';
import { ELogLevel } from './ELogLevel.enum';
import { ILogEntry } from './ILogEntry';
import { environment } from '../../../environments/environment';

const DB_NAME = 'logging';
const LOGS_STORE_NAME = 'logs';
const MAX_LOGS = 150;

@Injectable({
  providedIn: 'root'
})
export class LoggingService implements OnDestroy {
  static instance: LoggingService;
  /**
   * If the user has been notified that there is not a proper instance
   */
  static loggedInvalidInit = false;

  private _db: IDBPDatabase;
  private _dbInit = false;
  private _preDbLogs: ILogEntry[] = [];

  constructor(private _http: HttpClient) {
    if (!LoggingService.instance) {
      LoggingService.instance = this;
    } else {
      this.log(this.constructor.name, ELogLevel.WARNING, 'Multiple instances of "LoggingService" were created.');
      LoggingService.instance = this;
    }

    // Expose to browser for debugging
    if (!environment.production) {
      window['DojrpServices'] = { ...window['DojrpServices'], LoggingService: LoggingService.instance };
    }

    // Expose advanced exports to windows during debugging and production to allow usage in debugging problems in live
    window['dojExportLogsAdvanced'] = this.exportLogsAdvanced.bind(this);
    window['dojDownloadLogsAdvanced'] = this.downloadLogsAdvanced.bind(this);

    void this._initDatabase();
  }

  ngOnDestroy() {
    if (LoggingService.instance === this) {
      LoggingService.instance = null;
    }

    // tslint:disable-next-line: no-unsafe-any
    if (window['DojrpServices']?.['LoggingService'] === this) {
      // tslint:disable-next-line: no-unsafe-any
      delete window['DojrpServices']['LoggingService'];
    }
  }

  /**
   * Logs `args` as an error coming from `source`
   */
  error(source: string, ...args: any[]) {
    this.log(source, ELogLevel.ERROR, ...args);
  }

  /**
   * Logs `args` as a warning coming from `source`
   */
  warning(source: string, ...args: any[]) {
    this.log(source, ELogLevel.WARNING, ...args);
  }

  /**
   * Logs `args` as an informational log coming from `source`
   */
  info(source: string, ...args: any[]) {
    this.log(source, ELogLevel.INFO, ...args);
  }

  /**
   * Logs `args` as debug information coming from `source`
   */
  debug(source: string, ...args: any[]) {
    this.log(source, ELogLevel.DEBUG, ...args);
  }

  /**
   * Logs data into the console and database
   * @param source The source of the log
   * @param level The level/type of log
   * @param args The data of the log
   */
  log(source: string, level: ELogLevel, ...args: any[]) {
    if (!source) {
      source = 'Unknown';
    }

    const consoleOutput = this._getConsoleForLevel(level);

    if (consoleOutput) {
      const styling = this._getConsoleStyling(level);
      if (styling) {
        consoleOutput(`%c[${source}] `, styling, ...args);
      } else {
        consoleOutput(`[${source}] `, ...args);
      }
    }

    const entry: ILogEntry = {
      source: source,
      timestamp: new Date(),
      level: level,
      args: args
    };

    // If the database has been initialized store the log entry there, otherwise store it for entry later
    if (this._dbInit) {
      void this._storeEntry(entry);
    } else {
      this._preDbLogs.push(entry);
    }
  }

  /**
   * Exports the logs to a text format
   * @param num the max number of log entries to export, -1 will export the entire database
   */
  async exportLogs(num = -1): Promise<string> {
    // Exclude debug logs to prevent the log being unreadable
    const entries = (await this._getEntries(num)).filter((entr) => entr.level !== ELogLevel.DEBUG);

    return entries.map((entry) =>
      `[${entry.timestamp.toISOString()}] [${this.getLevelName(entry.level)}/${entry.source}] ${entry.args.map((arg) => this._stringify(arg)).join(' ')}`)
      .join('\n');
  }

  async exportLogsAdvanced(num = -1): Promise<ILogEntry[]> {
    const entries = await this._getEntries(num);

    return entries;
  }

  async downloadLogsAdvanced(num = -1): Promise<void> {
    const entries = await this.exportLogsAdvanced(num);

    const a = document.createElement('a');
    const file = new Blob([JSON.stringify(entries)], { type: 'application/json' });
    a.href = URL.createObjectURL(file);
    a.download = `portal-advanced-log-${new Date().toISOString()}.json`;
    a.click();
  }

  /**
   * Clears all local logs
   */
  async clearLogs() {
    const tx = this._db.transaction(LOGS_STORE_NAME, 'readwrite');
    const store = tx.objectStore(LOGS_STORE_NAME);

    await store.clear();

    await tx.done;
  }

  /**
   * Exports logs to hastebin and provides a link
   *
   * **Currently broken due to hastebin cors errors, code is here for future reference**
   *
   * @param num the amount of logs to export
   * @deprecated
   */
  async uploadLogs(num = -1): Promise<string> {
    const logs = await this.exportLogs(num);

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'text/plain');

    // TODO: CORS error prevents this from working but there is an active MR on the hastebin server repo to fix it.
    // Right now it will just throw an error, but this function is not called.
    // A CORS proxy could be used but it is not a super stable solution
    return this._http.post('https://hastebin.com/documents', logs, { headers: headers, observe: 'response', })
      .pipe(map((res) => res.body.toString())).toPromise();
  }

  /*
   * Misc Helpers
   */

  /**
   * Gets the formatted name of the given `level`
   * @param level the `ELogLevel`
   */
  getLevelName(level: ELogLevel): string {
    switch (level) {
      case ELogLevel.ERROR:
        return 'ERROR';
      case ELogLevel.WARNING:
        return 'WARNING';
      case ELogLevel.INFO:
        return 'INFO';
      case ELogLevel.DEBUG:
        return 'DEBUG';
      default:
        return 'UNKNOWN';
    }
  }

  /**
   * Converts `obj` to a string
   */
  private _stringify(obj: any): string {
    if (typeof obj === 'bigint'
      || typeof obj === 'boolean'
      || typeof obj === 'number'
      || typeof obj === 'string'
      || typeof obj === 'undefined') {
      return `${obj}`;
    } else {
      if (obj instanceof Error) {
        if (obj.stack) {
          if (navigator.userAgent.toLowerCase().includes('firefox')) {
            return `${obj.name}: ${obj.message}\n${obj.stack}`;
          } else {
            return obj.stack;
          }
        } else {
          return `${obj.name}: ${obj.message}`;
        }
      } else {
        return JSON.stringify(obj);
      }
    }
  }

  /*
   * Browser Console Helpers
   */

  /**
   * Gets the proper console to output for a given `level`
   * @param level The target `ELogLevel`
   */
  private _getConsoleForLevel(level: ELogLevel): (...args: any[]) => void {
    switch (level) {
      case ELogLevel.ERROR:
        // tslint:disable-next-line:no-console
        return (...args: any[]) => console.error(...args);
      case ELogLevel.WARNING:
        // tslint:disable-next-line:no-console
        return (...args: any[]) => console.warn(...args);
      case ELogLevel.INFO:
        // tslint:disable-next-line:no-console
        return (...args: any[]) => console.log(...args);
      case ELogLevel.DEBUG:
        // tslint:disable-next-line:no-console
        return (...args: any[]) => console.debug(...args);
    }
  }

  /**
   * Gets the appropriate styling for a given `level`
   * @param level The target `ELogLevel`
   */
  private _getConsoleStyling(level: ELogLevel): string {
    switch (level) {
      case ELogLevel.ERROR:
      // return 'font-weight: bold;';
      case ELogLevel.WARNING:
      // return 'font-weight: bold;';
      case ELogLevel.INFO:
      // return 'font-weight: bold; color: #BDBDBD;';
      case ELogLevel.DEBUG:
        return 'font-weight: bold; color: #BDBDBD;';
    }
  }

  /*
   * Database functions
   */

  /**
   * Initializes the database, and stores any pending logs
   */
  private async _initDatabase() {
    this._db = await openDB(DB_NAME, 1, {
      async upgrade(db, oldV, newV, tx) {
        // For some reason `this` is undefined, so access methods statically here
        if (oldV === 0) {
          await LoggingService.instance._defineDBSchema(db, tx);
        } else {
          await LoggingService.instance._migrateDB(oldV, newV, db, tx);
        }
      },
      blocked() { },
      blocking() { },
      terminated() { }
    });

    this._dbInit = true;

    // If any logs should have been stored, store them now
    if (this._preDbLogs.length > 0) {
      for (const entry of this._preDbLogs) {
        await this._storeEntry(entry);
      }

      this._preDbLogs = [];
    }
  }

  /**
   * Migrates from `oldVersion` to `newVersion`
   */
  private async _migrateDB(oldVersion: number, newVersion: number, db: IDBPDatabase, tx: IDBPTransaction) {
    // First version so no migrations exist
    LoggingService.instance.error(this.constructor.name, 'Unknown database migration attempted from ', oldVersion, ' to ', newVersion, '. Creating fresh database...');
    await this._defineDBSchema(db, tx);
    return;
  }

  /**
   * Creates object stores for the database, used in initial creation
   */
  private async _defineDBSchema(db: IDBPDatabase, _tx: IDBPTransaction) {
    db.createObjectStore(LOGS_STORE_NAME, { autoIncrement: true });

    return;
  }

  /**
   * Stores the given `entry` in the database
   * @param entry The `ILogEntry` to store
   */
  private async _storeEntry(entry: ILogEntry) {
    const tx = this._db.transaction(LOGS_STORE_NAME, 'readwrite');
    const store = tx.objectStore(LOGS_STORE_NAME);

    await store.add(entry);

    await this._trimDatabase(tx);

    await tx.done;
  }

  /**
   * Checks the size of the database and trims logs (starting from the oldest) to the max number of stored logs (if needed)
   */
  private async _trimDatabase(tx: IDBPTransaction<unknown, ['logs']>) {
    const store = tx.objectStore(LOGS_STORE_NAME);

    const logCount = await store.count();

    if (logCount > MAX_LOGS) {
      const logsOver = logCount - MAX_LOGS; // Number of logs to remove
      const cursor = await store.openCursor();

      for (let i = 0; i < logsOver; i++) {
        await store.delete(cursor.key);

        await cursor.continue();
      }
    }

    // Do NOT finish the transaction as it does not create it
  }

  /**
   * @returns all stored log entries
   * @param num the number of log entries to retrieve
   */
  private async _getEntries(num = -1): Promise<ILogEntry[]> {
    const tx = this._db.transaction(LOGS_STORE_NAME, 'readonly');
    const store = tx.objectStore(LOGS_STORE_NAME);

    const entries = await store.getAll();

    await tx.done;

    if (num < 0) {
      return entries;
    } else {
      return entries.slice(0, num);
    }
  }
}
