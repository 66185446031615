import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppDataService } from '../../core/app-data.service';
import { UserService } from '../user.service';
import { LoggingHelper } from '../../core/logging/helper';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard extends LoggingHelper implements CanActivate {

  constructor(private _appData: AppDataService, private _router: Router, private _user: UserService) { super(); }

  canActivate(
    next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {
    const returnUrl = next.queryParams['returnUrl'] as string ?? '/user/home';

    if (this._appData.User) {
      return this._router.parseUrl(returnUrl);
    }

    if (!this._appData.User && this._appData.Token && this._appData.Token.length > 0) {
      // User has a token and no user, try to get the current user
      return this._user.getCurrentUser().pipe(
        catchError((err) => {
          this.logError(err);
          this._appData.logOut();
          return of(true);
        }),
        map(
          (user) => {
            if (typeof user === 'boolean') { return user; }

            if (user && user.userId > 0) {
              return this._router.parseUrl(returnUrl);
            }

            return true;
          }
        )
      );
    }

    return true;
  }

}
