import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Error404Component } from './core/components/views/error-404/error-404.component';
import { AuthAdminGuard } from './services/auth/admin.guard';
import { AuthCadGuard } from './services/auth/cad.guard';
import { AuthLivemapGuard } from './services/auth/livemap.guard';
import { LoginGuard } from './services/auth/login.guard';
import { AuthStaffGuard } from './services/auth/staff.guard';
import { AuthUserGuard } from './services/auth/user.guard';
import { AuthWarrantsGuard } from './services/auth/warrants.guard';

const routes: Routes = [
  { path: '', redirectTo: '/user/home', pathMatch: 'full' },
  { path: '404', component: Error404Component },
  { path: 'user', loadChildren: () => import('./user/user.module').then((m) => m.UserModule), canActivate: [AuthUserGuard] },
  {
    path: 'staff',
    loadChildren: () => import('./staff/staff.module').then((m) => m.StaffModule),
    canActivate: [AuthUserGuard],
    canActivateChild: [AuthStaffGuard]
  },
  // Desktop Route
  // { path: 'desktop', loadChildren: () => import('./desktop/desktop.module').then(m => m.DesktopModule) },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'map',
    loadChildren: () => import('./livemap/livemap.module').then((m) => m.LivemapModule),
    canActivate: [AuthUserGuard],
    canActivateChild: [AuthLivemapGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthUserGuard],
    canActivateChild: [AuthAdminGuard]
  },
  {
    path: 'cad',
    loadChildren: () => import('./cad/cad.module').then((m) => m.CadModule),
    canActivate: [AuthUserGuard],
    canActivateChild: [AuthCadGuard]
  },
  {
    path: 'warrants',
    loadChildren: () => import('./warrants/warrants.module').then((m) => m.WarrantsModule),
    canActivate: [AuthUserGuard],
    canActivateChild: [AuthWarrantsGuard]
  },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
