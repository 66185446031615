import { ELogLevel } from './ELogLevel.enum';
import { LoggingService } from './logging.service';

/**
 * Exposes methods to children that allow auto-fills the source field for any logging calls
 */
export class LoggingHelper {
  protected logError(...args: any[]) {
    this.log(ELogLevel.ERROR, ...args);
  }

  protected logWarning(...args: any[]) {
    this.log(ELogLevel.WARNING, ...args);
  }

  protected logInfo(...args: any[]) {
    this.log(ELogLevel.INFO, ...args);
  }

  protected logDebug(...args: any[]) {
    this.log(ELogLevel.DEBUG, ...args);
  }

  protected log(level: ELogLevel, ...args: any[]) {
    if (LoggingService.instance) {
      LoggingService.instance.log(this.constructor.name, level, ...args);
    } else if (!LoggingService.loggedInvalidInit){
      LoggingService.loggedInvalidInit = true;
      // tslint:disable-next-line: no-console
      console.error(`No Active Logging Service, failed to log: [${this.constructor.name}] [${level}]`, ...args);
      alert('The logging service did not properly initialize, reload the page to fix it');
    }
  }
}
