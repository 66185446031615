import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { IServer } from '../models/interfaces/IServer';
import { Observable } from 'rxjs';
import { ICadPatrolUnit } from '../models/interfaces/ICadPatrolUnit';
import { EUnitClass } from '../models/enums/unit-class.enum';
import { EDepartment } from '../models/enums/department.enum';
import { map } from 'rxjs/operators';
import {IServerDTO} from '../models/interfaces/IServerDTO';
import { ICadPatrolGroup } from '../models/interfaces/ICadPatrolGroup';
import { ICadBeat } from '../models/interfaces/ICadBeat';
import { IDepartmentSubdivision } from '../models/interfaces/IDepartmentSubdivision';

@Injectable({
  providedIn: 'root'
})
export class CadPatrolService {

  constructor(private _http: HttpService) { }

  getServersAsync(): Observable<IServer[]> {
    return this._http.sendGetAsync<IServer[]>(`cad/patrol/servers`, false)
      .pipe(map((res) => res.body));
  }

  /**
   * Gets servers with metadata
   */
  getServersWithDataAsync(): Observable<IServerDTO[]> {
    return this._http.sendGetAsync<IServerDTO[]>(`cad/patrol/servers2`, false)
      .pipe(map((res) => res.body));
  }

  updateServer(value: IServer): Observable<boolean> {
    return this._http.sendPostAsync(`cad/patrol/server`, value)
      .pipe(map((res) => res.ok));
  }

  getBeatsAsync(): Observable<ICadBeat[]> {
    return this._http.sendGetAsync<ICadBeat[]>(`cad/patrol/beats`)
      .pipe(map((res) => res.body));
  }

  getSubdivisionsAsync(): Observable<IDepartmentSubdivision[]> {
    return this._http.sendGetAsync<IDepartmentSubdivision[]>(`cad/patrol/subdivisions`)
      .pipe(map((res) => res.body));
  }

  getPatrolUnitAsync(userId: number): Observable<ICadPatrolUnit> {
    return this._http.sendGetAsync<ICadPatrolUnit>(`cad/patrol/unit/${userId}`)
      .pipe(map((res) => res.body));
  }

  getPatrolUnitsAsync(serverId: number): Observable<ICadPatrolUnit[]> {
    return this._http.sendGetAsync<ICadPatrolUnit[]>(`cad/patrol/units/${serverId}`)
      .pipe(map((res) => res.body));
  }

  insertOrUpdatePatrolUnitAsync(patrolUnit: ICadPatrolUnit): Observable<ICadPatrolUnit> {
    return this._http.sendPostAsync<ICadPatrolUnit>(`cad/patrol/unit/save`, patrolUnit)
      .pipe(map((res) => res.body));
  }

  updatePatrolUnitStatusAsync(userId: number, statusId: number): Observable<boolean> {
    return this._http.sendGetAsync(`cad/patrol/unit/status/${userId}/${statusId}`)
      .pipe(map((res) => res.ok));
  }

  updatePatrolUnitCallAsync(userId: number, callId: number): Observable<boolean> {
    if (callId == null) {
      return this._http.sendGetAsync(`cad/patrol/unit/call/clear/${userId}`)
        .pipe(map((res) => res.ok));
    }
    else {
      return this._http.sendGetAsync(`cad/patrol/unit/call/${userId}/${callId}`)
        .pipe(map((res) => res.ok));
    }
  }

  updatePatrolUnitIdentifierAsync(userId: number, identifier: string): Observable<boolean> {
    return this._http.sendPostAsync(`cad/patrol/unit/identifier/${userId}`, `"${identifier}"`)
      .pipe(map((res) => res.ok));
  }

  getPatrolGroupAsync(groupId: number): Observable<ICadPatrolGroup> {
    return this._http.sendGetAsync<ICadPatrolGroup>(`cad/patrol/group/${groupId}`)
      .pipe(map((res) => res.body));
  }

  getPatrolGroupsAsync(serverId: number): Observable<ICadPatrolGroup[]> {
    return this._http.sendGetAsync<ICadPatrolGroup[]>(`cad/patrol/groups/${serverId}`)
      .pipe(map((res) => res.body));
  }

  updatePatrolGroupIdentifierAsync(groupId: number, identifier: string): Observable<boolean> {
    return this._http.sendPostAsync(`cad/patrol/group/identifier/${groupId}`, `"${identifier}"`)
    .pipe(map((res) => res.ok));
  }

  createPatrolGroupAsync(patrolGroup: ICadPatrolGroup, patrolUnitId: number): Observable<ICadPatrolGroup> {
    return this._http.sendPostAsync<ICadPatrolGroup>(`cad/patrol/group/create/${patrolUnitId}`, patrolGroup)
      .pipe(map((res) => res.body));
  }

  addUnitToPatrolGroupAsync(patrolUnit: ICadPatrolUnit, patrolGroupId: number | null): Observable<ICadPatrolGroup> {
    return this._http.sendPostAsync<ICadPatrolGroup>(`cad/patrol/unit/group/${patrolUnit.userId}`, patrolGroupId)
      .pipe(map((res) => res.body));
  }

  updatePatrolGroupStatusAsync(groupId: number, statusId: number): Observable<boolean> {
    return this._http.sendGetAsync(`cad/patrol/group/status/${groupId}/${statusId}`)
      .pipe(map((res) => res.ok));
  }

  deletePatrolGroupAsync(groupId: number): Observable<boolean> {
    return this._http.sendDeleteAsync(`cad/patrol/group/delete/${groupId}`)
      .pipe(map((res) => res.ok));
  }

  updatePatrolGroupCallAsync(groupId: number, callId: number): Observable<boolean> {
    if (callId == null) {
      return this._http.sendGetAsync(`cad/patrol/group/call/clear/${groupId}`)
        .pipe(map((res) => res.ok));
    }
    else {
      return this._http.sendGetAsync(`cad/patrol/group/call/${groupId}/${callId}`)
        .pipe(map((res) => res.ok));
    }
  }

  updatePatrolUnitBeat(userId: number, beatId: number): Observable<boolean> {
    return this._http.sendPostAsync(`cad/patrol/unit/beat/${userId}`, `"${beatId}"`)
    .pipe(map((res) => res.ok));
  }

  updatePatrolGroupBeat(groupId: number, beatId: number): Observable<boolean> {
    return this._http.sendPostAsync(`cad/patrol/group/beat/${groupId}`, `"${beatId}"`)
    .pipe(map((res) => res.ok));
  }

  updatePatrolUnitSubdivisions(userId: number, activeId: number, flags: string): Observable<boolean> {
    return this._http.sendPostAsync(`cad/patrol/unit/subdivisions/${userId}/${activeId || -1}`, `"${flags}"`)
    .pipe(map((res) => res.ok));
  }

  updatePatrolGroupSubdivisions(groupId: number, activeId: number, flags: string): Observable<boolean> {
    return this._http.sendPostAsync(`cad/patrol/group/subdivisions/${groupId}/${activeId || -1}`, `"${flags}"`)
    .pipe(map((res) => res.ok));
  }

  /**
   * Gets the unit class of a unit, which includes calculating the class and storing it into
   * the ICadPatrolUnit object if necessary.
   * @param unit  The unit whose class to obtain
   */
  getUnitClass(unit: ICadPatrolUnit): EUnitClass {
    if (!unit) {
      return null;
    }

    if (unit.unitClass) {
      return unit.unitClass;
    }

    switch (unit.departmentId) {
      case EDepartment.FIRE:
        unit.unitClass = EUnitClass.Fire;
        break;
      case EDepartment.BCSO:
      case EDepartment.LSPD:
      case EDepartment.SAHP:
        unit.unitClass = EUnitClass.Leo;
        break;
      case EDepartment.COMM:
        unit.unitClass = EUnitClass.Comms;
        break;
      case EDepartment.CIV:
        unit.unitClass = EUnitClass.CivTow;
        break;
      default:
        unit.unitClass = EUnitClass.Other;
    }

    return unit.unitClass;
  }

  /**
   * @returns `true` if `unit` is a fire unit, otherwise `false`
   */
  isFireUnit(unit: ICadPatrolUnit): boolean {
    return this.getUnitClass(unit) === EUnitClass.Fire;
  }

  /**
   * @returns `true` if `unit` is a LEO unit, otherwise `false`
   */
  isLeoUnit(unit: ICadPatrolUnit): boolean {
    return this.getUnitClass(unit) === EUnitClass.Leo;
  }

  /**
   * @returns `true` if `unit` is a dispatcher, otherwise `false`
   */
  isDispatchUnit(unit: ICadPatrolUnit): boolean {
    return this.getUnitClass(unit) === EUnitClass.Comms;
  }

  isCivTowUnit(unit: ICadPatrolUnit) {
    return this.getUnitClass(unit) === EUnitClass.CivTow;
  }
}
