<div class="modal">
    <p class="modal-title">Subdivision Configuration</p>
    <button mat-raised-button color="warn" class="deactivate-btn" (click)="deactivateAll()">Deactivate All</button>
    <table mat-table [dataSource]="dataSource" class="table">
        <ng-container matColumnDef="glyph">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let subdivision">
            <mat-icon *ngIf="subdivision.iconType == 'mat'" class="icon">{{subdivision.iconName}}</mat-icon>
            <fa-icon *ngIf="subdivision.iconType == 'fa'" [icon]="subdivision.iconName" class="icon"></fa-icon>
            <fa-icon *ngIf="subdivision.iconType == 'fab'" [icon]="['fab', subdivision.iconName]" class="icon"></fa-icon>
          </td>
        </ng-container>
    
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let subdivision">
            {{subdivision.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let subdivision">
                <mat-button-toggle-group [name]="subdivision.subdivisionId" (change)="statusChange($event, subdivision.subdivisionId)" value="{{selections[subdivision.subdivisionId]}}">
                    <mat-button-toggle value="active" class="toggle-active">Active</mat-button-toggle>
                    <mat-button-toggle value="standby" class="toggle-standby">Standby</mat-button-toggle>
                    <mat-button-toggle value="inactive" class="toggle-inactive">Inactive</mat-button-toggle>
                </mat-button-toggle-group>
            </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let rowData; columns: columns"></tr>
    </table>

    <br>
    <button mat-raised-button color="primary" (click)="save()">Save</button>
</div>
  