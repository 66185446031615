<div class="container mat-typography">
  <div>
    <h2>Not Found</h2>
    <p>
      The page you tried to access could not be found.
    </p>
    <p>
      Click <a href="/user/home">here</a> to return to the main page.
    </p>
  </div>
</div>
