import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { IDepartmentRank } from '../../../../models/interfaces/IDepartmentRank';

@Component({
  selector: 'app-dropdown-ranks',
  templateUrl: './dropdown-ranks.component.html',
  styleUrls: ['./dropdown-ranks.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  exportAs: 'ngModel'
})
export class DropdownRanksComponent implements OnInit {

  @Input() ranks: IDepartmentRank[] = [];
  // @Input() rankId: number = null;
  @Input() rank: IDepartmentRank = null;

  @Output() rankChanged = new EventEmitter<IDepartmentRank>();
  @Output() valid: boolean = false;

  @Input() selectionRequired: boolean = true;

  constructor() { }

  ngOnInit() {
  }

  /**
   * Updates the rank and notifies all listeners
   */
  onRankChanged(rankId: number): void {
    this.rank = this.ranks.find((r) => r.rankId === rankId);
    this._updateValidation();
    this.rankChanged.emit(this.rank);
  }

  /**
   * Sets the current rank
   */
  setRank(r: IDepartmentRank): void {
    this.rank = r;
    this._updateValidation();
    this.rankChanged.emit(this.rank);
  }

  private _updateValidation() {
    this.valid = !!this.rank;
  }

}
