import { Component, OnInit, HostBinding, OnDestroy, HostListener } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { CanDeactivateState } from './services/auth/unload.guard';
import { AppDataService } from './core/app-data.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('class') public cssClass = null;

  private isCadObs = new BehaviorSubject<boolean>(false);

  private isDesktopSrc = new BehaviorSubject(false);
  isDesktop = this.isDesktopSrc.asObservable();

  private _destroyed$ = new Subject();

  constructor(private _router: Router,
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
    private _appData: AppDataService) {
    /**
     * Fixes for the unload guard. Prevents a double back-navigation in certain circumstances
     */
    // if the user clicks the back button, ask the CanDeactivateGuard to defend against this.
    window.onpopstate = () => CanDeactivateState.defendAgainstBrowserBackButton = true;

    // Upon successful navigation, ensure that the CanDeactivateGuard no longer defends against back button clicks
    this._router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      tap(() => CanDeactivateState.defendAgainstBrowserBackButton = false),
      takeUntil(this._destroyed$)
    ).subscribe();
  }

  ngOnInit() {
    this.subscribeToRouter();

    this._matIconRegistry.addSvgIcon(
      'police',
      this._domSanitizer.bypassSecurityTrustResourceUrl('../../assets/police_icon.svg')
    );
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }

  subscribeToRouter(): void {
    this._router.events.pipe(takeUntil(this._destroyed$)).subscribe((event: RouterEvent) => {
      if (event && event instanceof NavigationEnd) {
        if (event.url && event.url.includes('/cad')) {
          this.isCadObs.next(true);
        }
        else {
          this.isCadObs.next(false);
        }
        this.isDesktopSrc.next(event.url && (event.url.includes('desktop') || event.url.includes('map')));
      }
    });
  }

  @HostListener('window:message', ['$event'])
  handleKeyDown(event: MessageEvent) {
    const data = event.data;

    if (typeof data !== 'object' || data['type'] !== 'token') return;

    if (this._appData.Token) return;

    this._appData.Token = data.token;

    this._router.navigateByUrl('/user/home');
  }
}
