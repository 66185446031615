import { ISettingsSink } from '../../../models/interfaces/ISettingsSink';

export const SETTINGS_STORAGE_PREFIX = 's-';

/**
 * A basic sink that saves to local storage. Used as a fallback for older browsers
 */
export class LocalSettingsSink implements ISettingsSink {
    save(settings: Record<string, any>): void {
        for (const settingKey of Object.keys(settings)) {
            // Store the object in local storage, prefixing with "s-" to allow easier loading
            localStorage.setItem(`${SETTINGS_STORAGE_PREFIX}${settingKey}`, JSON.stringify(settings[settingKey]));
        }
    }

    load(): Record<string, any> {
        const settingsKeys = [];

        for (let i = 0; i < localStorage.length; i++) {
            // Iterate over all local storage keys and select ones that are stored as settings
            const key = localStorage.key(i);
            if (key.substr(0, SETTINGS_STORAGE_PREFIX.length) === SETTINGS_STORAGE_PREFIX) {
                settingsKeys.push(key.substring(SETTINGS_STORAGE_PREFIX.length)); // Push the key excluding the prefix
            }
        }

        const loadedSettings: Record<string, any> = {};

        for (const settingKey of settingsKeys) {
            loadedSettings[settingKey] = JSON.parse(localStorage.getItem(`${SETTINGS_STORAGE_PREFIX}${settingKey}`));
        }

        return loadedSettings;
    }

}
