import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { IDepartment } from '../../../../models/interfaces/IDepartment';
import { UserService } from '../../../../services/user.service';
import { LoggingHelper } from '../../../../core/logging/helper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dropdown-dept',
  templateUrl: './dropdown-dept.component.html',
  styleUrls: ['./dropdown-dept.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  exportAs: 'ngModel'
})
export class DropdownDeptComponent extends LoggingHelper implements OnInit, OnDestroy {

  depts: IDepartment[] = [];
  @Input() department: IDepartment = null;
  // @Input() departmentId: number = null;

  @Output() departmentChanged = new EventEmitter<IDepartment>();
  @Output() valid: boolean = false;

  @Input() selectionRequired: boolean = true;
  @Input() showAllDepts: boolean = false;
  @Input() disable: boolean = false;
  @Input() exclude: number[] = [];
  @Input() dropdownClass: string[] = [];

  private _destroyed$ = new Subject();

  constructor(private _userService: UserService) { super(); }

  ngOnInit() {
    this._userService.getDepartmentsAsync(this.showAllDepts).pipe(takeUntil(this._destroyed$)).subscribe(
      (depts) => {
        this.depts = depts.filter((dept) => !this.exclude.includes(dept.departmentId));
      },
      (err) => {
        this.logError(err);
        this.depts = [];
      }
    );
  }

  ngOnDestroy() {
    this._destroyed$.next();
  }

  /**
   * Set the current department
   */
  setDepartment(value: IDepartment) {
    this.department = value;
    this._updateValidation();
    this.departmentChanged.emit(this.department);
  }

  isValueNotNull() {
    return (this.department != null);
  }

  /**
   * Updates the current department and notifies any listeners
   */
  onDepartmentChanged(deptId: number): void {
    this.department = this.depts.find((d) => d.departmentId === deptId);

    this._updateValidation();

    this.departmentChanged.emit(this.department);
  }

  private _updateValidation() {
    this.valid = !!this.department;
  }

}
