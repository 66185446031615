// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  DOJAPIUrl: `https://api-dev.dojrp.com/api`,
  DOJAPIKey: `pWlXynXaZw5sl0TE9w2asfGJ3DoXjyNpR2g13ZGHz91oxjPRWE/WwZ3FUkrzrk0hGDjAPrif98Sqm3kweWWm/bBANUyk/8uVfueiiTCQWcwfsIwom/Mq371MJwBw4cm9GOxc0NCdIv1T2URGv8Dpb/z+8G7+UvKr3SnooLD9LAE=`
};
